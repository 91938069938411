import React, { useEffect, useState } from "react";
import Layout from "./layout.js";
import { Link } from "react-router-dom";
import * as Constants from '../config/constants';
import { useForm } from "react-hook-form";
import "@flaticon/flaticon-uicons/css/all/all.css";
import { useTranslation } from "react-i18next";
import { apiCall , alertifyMessage , manageNetworkError } from "../js/common.js";

import Email from '../images/email.png';
import Route from '../images/route.png';
import Headphone from '../images/headphone.png';




export default function Contact() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const { t } = useTranslation(["translation", "errorMessage"]);

  useEffect(() => {
    document.querySelector("input[type='tel']").setAttribute("maxlength", "15");
  }, []);

  const [inputMobileValue, setInputMobileValue] = useState("");

  const handleMobileInputChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^ +\d]/g, "");
    setInputMobileValue(numericValue);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  
  const onSubmit = async (data) => {
    var submitContact = await apiCall( 'post' , 'send-mail.php' , data);

    if( submitContact.status !== false ){
      if( submitContact.data.status_code === 1 ){
        alertifyMessage("success" ,  submitContact.data.message ); 
        reset();
        setInputMobileValue("");
      } else {
        alertifyMessage("error" ,  submitContact.data.message ); 
      }
    } else {
      manageNetworkError(submitContact , t);
    }
  };

  return (
    <Layout pageName={t("contact-us")}>

      <section className="breadcrumbs-section">
        <div className="breadcrumbs-mdiv container">
          <h1 className="breadcrumbs-title">Contact Us</h1>
          <div className="breadcrumbs-link-div">
            <Link to={Constants.LANDING_PAGE_URL} className="breadcrumbs-link">Home</Link>/
            <p className="breadcrumbs-link">Contact Us</p>
          </div>
        </div>
      </section>

      <section className="contact-us-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 border-class-right">
              <div className="contact-details">
                <h2 className="contact-big-title">Get In Touch With Us</h2>
              </div>
              <p className="contact-text">The flagship brand of More Industries, dedicated to providing you with premium quality flour products.</p>
              <div className="media d-flex align-items-start media-border pt-3 pb-sm-3">
                <img src={Route} alt="Location" className="img-fluid location-icon me-3"/>
                <div className="media-body">
                  <h3 className="mt-0 media-title">Location</h3>
                  <p className="p-text">G-27, Priya Avenue Phase - 1, Chhatral G.I.D.C., Chhatral, Gandhinagar, Gujarat - 382729</p>
                </div>
              </div>
              <div className="row pt-sm-4">
                <div className="col-xl-6">
                  <div className="media d-flex align-items-start pt-3">
                    <img src={Headphone} alt="Quick Contact" className="img-fluid location-icon me-3"/>
                    <div className="media-body">
                      <h3 className="mt-0 media-title">Phone</h3>
                      <div className="call-contact-items">
                        <a href="tel:+918734017801" className="mail-class">+91 87340 17801</a>
                        <br />
                        <a href="tel:+919824429293" className="mail-class">+91 98244 29293</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="media d-flex align-items-start pt-sm-3 pt-4">
                    <img src={Email} alt="Quick Email" className="img-fluid location-icon me-3"/>
                    <div className="media-body">
                      <h3 className="mt-0 media-title">Email</h3>
                      <div className="call-contact-items">
                        <a href="mailto:moreindustries06@gmail.com" className="mail-class">moreindustries06@gmail.com</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-5 pt-lg-0 pt-5">
              <div className="contact-details">
                <h2 className="contact-big-title">Send Your Message</h2>
              </div>
              <p className="contact-text">Our team is ready to answer all your questions.</p>
              <div className="contact-form">
                <form onSubmit={handleSubmit(onSubmit)} id="contact-us-form" autoComplete="off">
                  <div className="row">
                    <div className="form-group col-md-6 pb-2">
                      <input type="text" className="form-control" name="name" placeholder={t('name') + '*'}
                            {...register("name", {
                              required: true,
                              setValueAs: (value) => value.trim(),
                            })}/>
                          {errors.name && (<span className="text-danger">{t('errorMessage:required-enter-name')}</span>)}
                    </div>
                    <div className="form-group col-md-6 pb-2">
                      <input type="tel" className="form-control" placeholder={t('mobile-no') + '*'} maxLength="15" value={inputMobileValue} name="mobile_number"
                          {...register("mobile_number", {
                            required: t('errorMessage:required-enter-mobile-no'),
                            minLength: {
                              value: 8,
                              message: t('errorMessage:enter-atleast-character', {character: 8}),
                            },
                            setValueAs: (value) => value.trim()
                          })}
                          onChange={handleMobileInputChange}
                        />
                        {errors.mobile_number && (<span className="text-danger">{errors.mobile_number?.message}</span>)}
                    </div>
                    <div className="form-group col-md-12 pb-2">
                      <input type="text" className="form-control" name="email" placeholder={t('email')}
                            {...register("email", {
                              required: false,
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: t('errorMessage:required-enter-valid-email'),
                              },
                            })}
                          />
                          {errors.email && (<p className="mb-0 text-danger">{errors.email?.message}</p>)}
                    </div>
                    <div className="form-group col-md-12 pb-2">
                      <textarea className="form-textarea form-control" name="message" defaultValue={""} placeholder={t('comments')}
                            {...register("message", { required: false })}/>
                    </div>
                    <div className="col-lg-12 d-none">
                        <div className="form-group">
                          <label htmlFor="webname">{t('webname')}</label>
                          <input type="text" name="webname" className="form-control" id="webname" placeholder={t('webname')}
                            {...register("webname", { required: false })}/>
                        </div>
                      </div>
                    <div className="form-group col-md-12">
                      <button type="submit" name="submit" className="btn submit-btn send-now-btn">Send Now !</button>
                    </div>
                  </div>

                </form>
              </div>

            </div>

          </div>

        </div>
      </section>
      <section className="map">
        <div className="map-wrap">
          <iframe
            width="100%"
            title="map"	   
            className="iframe-contact"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14659.085238675967!2d72.42533163315491!3d23.287757956864674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395c248e7f23519b%3A0x4215d3e92ac0ad75!2sGIDC%20Chhatral%2C%20Chhatral%2C%20Gujarat%20382729!5e0!3m2!1sen!2sin!4v1712228548662!5m2!1sen!2sin"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
    </Layout>
  );
}
