import React from 'react';
import { FrontendRoute } from './routes/FrontendRoute.js';
import './js/common.js';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/style.css';


function App() {
  return (
    	  
    <div className="App" >
		<FrontendRoute / >
    </div>
  );
}

export default App;
