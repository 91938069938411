import React from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import { Link } from "react-router-dom";
import * as Constants from "../../config/constants";
import { useTranslation } from "react-i18next";
import mainLogo from '../../images/logo.png';

export default function Footer() {
	const date = new Date();
	const { t } = useTranslation();
	
	return (
		<footer className="footer">
			<div className="container footer-content">
				<div className="row footer-row">
					<div className="col-lg-3">
						<div className="logo-sec">
							<Link to={Constants.LANDING_PAGE_URL}><img src={mainLogo} alt={t('sonamor')} className="footer-logo img-fluid" /></Link>
							<p className="text">At Sonamor, the flagship brand of More Industries we take immense pride in crafting a diverse range of kitchen essentials, including sooji, maida, besan, and attas.</p>
						</div>
					</div>
					<div className="col-lg-2 col-md-3 col-sm-6 col-12 footer-links-col">
						<h4 className="footer-link-head">{t('products')}</h4>
						<div className="links">
							<div className="d-flex flex-column links-col">
								<Link to={Constants.SOOJI_PAGE_URL} className="footer-links">Sooji</Link>
								<Link to={Constants.MAIDA_PAGE_URL} className="footer-links">Maida</Link>
								<Link to={Constants.BESAN_PAGE_URL} className="footer-links">Besan</Link>
								<Link to={Constants.ATTA_PAGE_URL} className="footer-links">Atta</Link>
							</div>
						</div>
					</div>

					<div className="col-lg-3 col-md-3 col-sm-6 col-12 footer-links-col">
						<h4 className="footer-link-head">{t('quick-links')}</h4>
						<div className="links">
							<div className="d-flex flex-column links-col">
								<Link to={Constants.LANDING_PAGE_URL} className="footer-links">{t('home')}</Link>
								<Link to={Constants.ABOUT_US_PAGE_URL} className="footer-links">{t('about')}</Link>
								<Link to={Constants.PRODUCTS_PAGE_URL} className="footer-links">{t('products')}</Link>
								<Link to={Constants.CONTACT_US_PAGE_URL} className="footer-links">{t('contact-us')}</Link>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-12 footer-links-col">
						<h4 className="footer-link-head">{t('contact-us')}</h4>
						<div className="location-sec links">
							<div className="our-location-div no-hover">
								<div className="location">
									<i className="fi fi-rr-marker footer-icon mt-1"></i>
									<p className="location-text">G-27, Priya Avenue Phase - 1, Chhatral G.I.D.C., Chhatral, Gandhinagar, Gujarat - 382729</p>
								</div>
							</div>
							<div className="our-location-div">
								<div className="location align-items-center">
									<i className="fi fi-rr-mobile-button footer-icon"></i>
									<div>
										<a href="tel:+918734017801" rel="noreferrer" className="mail-call-text">+91 87340 17801<span className="text-light">,</span></a> <a href="tel:+919824429293" rel="noreferrer" className="mail-call-text">+91 98244 29293</a> 
									</div>
								</div>
							</div>
							<div className="our-location-div">
								<div className="location align-items-center">
									<i className="fi fi-rr-envelope footer-icon"></i>
									<a href="mailto:moreindustries06@gmail.com" rel="noreferrer" className="mail-call-text">moreindustries06@gmail.com</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="sub-footer container">
				<div>
					<div className="content">
						<p className="all-rights">Copyright © - {date.getFullYear()}. {t('sonamor')}. All Rights Reserved.</p>
						<p className="develop-by">Developed by <a className="twt-link" target="_blank" rel="noreferrer" href="https://www.thewildtigers.com/">TWT</a>.</p>
					</div>
				</div>
			</div>
		</footer>
	);
}
