import * as twtConfig from "./twt_config";  

export const API_BASE_URL = twtConfig.API_BASE_URL;
export const BACKEND_FILE_URL = twtConfig.BACKEND_FILE_URL;
export const SITE_URL = twtConfig.SITE_URL;
export const BASE_PATH = twtConfig.BASE_PATH;
export const LANGUAGE_FOLDER_URL = twtConfig.LANGUAGE_FOLDER_URL;
export const LOGIN_COOKIE_NAME = twtConfig.LOGIN_COOKIE_NAME;

export const LANDING_PAGE_URL = "/";
export const HOME_PAGE_URL = "/home.html";
export const ABOUT_US_PAGE_URL = "/about.html";
export const CONTACT_US_PAGE_URL = "/contact-us.html";
export const MAIDA_PAGE_URL = "/maida.html";
export const SOOJI_PAGE_URL = "/sooji.html";
export const BESAN_PAGE_URL = "/besan.html";
export const ATTA_PAGE_URL = "/atta.html";
export const PRODUCTS_PAGE_URL = "/products.html";