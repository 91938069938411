import React, {useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import Layout from "./layout.js";
import * as Constants from '../config/constants';
import WOW from "wowjs";
import "wowjs/dist/wow";
import "wowjs/css/libs/animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import bottomBg from '../images/img3.png';
import bannerImg1 from '../images/img1.png';
import bannerImg2 from '../images/img4.png';
import bannerImg3 from '../images/soji.png';
import wavingIcon from '../images/img2.png';
import wavingIcon2 from '../images/right-leaf.png';
import bbIcon1 from '../images/icon1.png';
import bbIcon2 from '../images/icon2.png';
import bbIcon3 from '../images/icon3.png';
import bbIcon4 from '../images/icon4.png';
import productStandImg from '../images/img7.png';
import brProductImage from '../images/img1.png';
import blProductImage from '../images/img5.png';
import trProductImage from '../images/img4.png';
import soojiImage from '../images/soji.png';
import maidaImage from '../images/maida.png';
import besanImage from '../images/besan.png';
import attaImage from '../images/atta.png';
import processIcon1 from '../images/icon8.svg';
import processIcon2 from '../images/icon9.svg';
import processIcon3 from '../images/icon10.svg';
import processIcon4 from '../images/icon11.svg';
import roundedCircle from '../images/rounded-circle.svg';
import whyChooseIcon1 from '../images/icon5.svg';
import whyChooseIcon2 from '../images/icon6.svg';
import whyChooseIcon3  from '../images/icon7.svg';
import checkCircle from '../images/check-circle.svg';

export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {t} = useTranslation();

  const wow = new WOW.WOW({
    live: false,
    boxClass: 'wow',
    offset: 0,
    mobile: true,
  });
  wow.init();

  const mainSlider = useRef(null);
  const next = () => {mainSlider.current.slickNext();};
  const previous = () => {mainSlider.current.slickPrev();};

  const mainBannerSlider = {
    dots: false,
    ref: mainSlider,
    focusOnSelect: false,   
    pauseOnHover: false,
    fade: true,
    cssEase: 'linear',
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    loop: true,
    speed: 1,
    slidesToShow: 1,
    afterChange: () => {
      const wow2 = new WOW.WOW({
        live: false,
        boxClass: 'wow2',
      });
      wow2.init();
      wow2.start();
    },
    onInit: () => {
      const wow2 = new WOW.WOW({
        live: false,
        boxClass: 'wow2',
      });
      wow2.init();
      wow2.start();
    },
  }

  return (
    <Layout pageName={t("home")}>
      <section className="main-banner-section">
        <button type="button" onClick={previous} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
        <button type="button" onClick={next} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
        <img src={bottomBg} alt="Background" className="img-fluid bottom-bg" />
        <div className="container">
          <Slider {...mainBannerSlider} className="main-slider">

            <div className="slider-content">
              <div className="row slider-content-row">
                <div className="col-lg-6 text-col">
                  <div className="text-content">
                    <h3 className="tag wow2 slideInLeft">Premium Quality</h3>
                    <h4 className="main-title wow2 slideInLeft" data-wow-delay="0.1s">Sooji</h4>
                    <h4 className="sub-title wow2 slideInLeft">ઉપમા | હલવા | ઉત્તપમ | સોજી ફૂલા</h4>
                    <Link to={Constants.SOOJI_PAGE_URL} className="secondary-btn wow2 fadeInUp">Read More</Link>
                  </div>
                </div>
                <div className="col-lg-6 image-col">
                  <div className="img-div justify-content-center">
                    <div className="main-img">
                      <img src={wavingIcon} alt="Icon" className="waving-icon wow2 fadeInUp" data-wow-delay="0.1s" />
                      <img src={bannerImg3} alt="Sooji" className="img-fluid banner-img wow2 fadeInUp" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div className="slider-content">
              <div className="row slider-content-row">
                <div className="col-lg-6 text-col">
                  <div className="text-content">
                    <h3 className="tag wow2 slideInLeft">Premium Quality</h3>
                    <h4 className="main-title wow2 slideInLeft" data-wow-delay="0.1s">Maida</h4>
                    <h4 className="sub-title wow2 slideInLeft">બ્રેડ | બિસ્કિટ | ટોસ્ટ | ખારી | કૂકીસ | કેક | સમોસા | કચોરી | ફરસી પુરી</h4>
                    <Link to={Constants.MAIDA_PAGE_URL} className="secondary-btn wow2 fadeInUp">Read More</Link>
                  </div>
                </div>
                <div className="col-lg-6 image-col">
                  <div className="img-div justify-content-center">
                    <div className="main-img">
                      <img src={wavingIcon} alt="Icon" className="waving-icon wow2 fadeInUp" data-wow-delay="0.1s" />
                      <img src={bannerImg2} alt="Maida" className="img-fluid banner-img wow2 fadeInUp" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-content">
              <div className="row slider-content-row">
                <div className="col-lg-6 text-col">
                  <div className="text-content">
                    <h3 className="tag wow2 slideInLeft">Premium Quality</h3>
                    <h4 className="main-title wow2 slideInLeft" data-wow-delay="0.1s">Besan</h4>
                    <h4 className="sub-title wow2 slideInLeft">મેથી ભજીયા | ગોટા | નમકીન</h4>
                    <Link to={Constants.BESAN_PAGE_URL} className="secondary-btn wow2 fadeInUp">Read More</Link>
                  </div>
                </div>
                <div className="col-lg-6 image-col">
                  <div className="img-div justify-content-center">
                    <div className="main-img">
                      <img src={wavingIcon} alt="Icon" className="waving-icon wow2 fadeInUp" data-wow-delay="0.1s" />
                      <img src={besanImage} alt="Besan" className="img-fluid banner-img wow2 fadeInUp" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-content">
              <div className="row slider-content-row">
                <div className="col-lg-6 text-col">
                  <div className="text-content">
                    <h3 className="tag wow2 slideInLeft">Chakki fresh</h3>
                    <h4 className="main-title wow2 slideInLeft" data-wow-delay="0.1s">Atta</h4>
                    <h4 className="sub-title wow2 slideInLeft">તંદૂરી રોટી | ફુલ્કા રોટી | પાનીપુરી </h4>
                    <Link to={Constants.ATTA_PAGE_URL} className="secondary-btn wow2 fadeInUp">Read More</Link>
                  </div>
                </div>
                <div className="col-lg-6 image-col">
                  <div className="img-div chakki-atta-img">
                    <div className="main-img">
                      <div className="info-box wow2 slideInRightOpacity" data-wow-delay="0.1s">
                        <p className="text"><span>0%</span> Maida</p>
                        <p className="text"><span>100%</span> Atta</p>
                      </div>
                      <img src={wavingIcon} alt="Icon" className="waving-icon wow2 fadeInUp" data-wow-delay="0.1s" />
                      <img src={bannerImg1} alt="Atta" className="img-fluid banner-img wow2 fadeInUp" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Slider>
        </div>
      </section>

      <section className="banner-bottom-sec">
          <div className="inner-content container">
            <div className="inner-card">
              <div className="icon-div">
                <img src={bbIcon1} alt="Natural Product" className="icon" />
              </div>
              <div className="text-content">
                <h4 className="counter text">100%</h4>
                <h5 className="title text">Natural Product</h5>
              </div>
            </div>
            <div className="inner-card">
              <div className="icon-div">
                <img src={bbIcon2} alt="Whole Wheat" className="icon" />
              </div>
              <div className="text-content">
                <h4 className="counter text">100%</h4>
                <h5 className="title text">Whole Wheat</h5>
              </div>
            </div>
            <div className="inner-card">
              <div className="icon-div">
                <img src={bbIcon3} alt="Promise Of Purity" className="icon" />
              </div>
              <div className="text-content">
                <h5 className="title text">Promise <br /> Of Purity</h5>
              </div>
            </div>
            <div className="inner-card">
              <div className="icon-div">
                <img src={bbIcon4} alt="3 Finger Tear Softness" className="icon" />
              </div>
              <div className="text-content">
                <h5 className="title text">3 Finger <br /> Tear Softness</h5>
              </div>
            </div>
          </div>
      </section>

      <section className="about-section">
        <img src={wavingIcon2} alt="Icon" className="waving-icon" />
        <div className="container">
          <div className="row about-section-row">
            <div className="col-xxl-5 col-lg-6 left-col img-col">
              <div className="img-div">
                <img src={brProductImage} alt="Atta" className="img-fluid product-img br-img wow fadeInRight" data-wow-delay="1.3s"/>
                <img src={blProductImage} alt="Besan" className="img-fluid product-img bl-img wow fadeInLeft" data-wow-delay="0.9s" />
                <img src={soojiImage} alt="Soji" className="img-fluid product-img tl-img wow fadeInLeft" />
                <img src={trProductImage} alt="Maida" className="img-fluid product-img tr-img wow fadeInRight" data-wow-delay="0.5s"/>
                <img src={productStandImg} alt={t('sonamor')} className="img-fluid product-stand" />
              </div>
            </div>
            <div className="col-xxl-7 col-lg-6 right-col text-content-col">
              <div className="text-content">
                <h1 className="common-heading">About sonamor</h1>
                <div className="p-text-div">
                  <p className="p-text">Welcome to Sonamor, the flagship brand of More Industries, dedicated to providing you with premium quality flour products. Sonamor has swiftly emerged as a trusted name in households and kitchens nationwide.</p>
                  <p className="p-text">At Sonamor, we take immense pride in crafting a diverse range of kitchen essentials, including sooji, maida, besan, and attas, all under the banner of excellence. Our commitment to quality is unwavering, ensuring that every product bearing the Sonamor name meets the highest standards of freshness, purity, and nutritional value. Driven by a passion for innovation and customer satisfaction, Sonamor is at the forefront of revolutionizing the flour industry. With a dedicated team of experts and cutting-edge technology, we continuously strive to enhance the quality and variety of our offerings, ensuring that every Sonamor product exceeds your expectations.</p>
                </div>
                <div className="tag"><span className="text">Healthy <span className="primary-color">Choices,</span> Healthy <span className="primary-color">Living</span></span></div>
                <Link to={Constants.ABOUT_US_PAGE_URL} className="primary-btn">Know More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-section">
        <div className="products-row">
          <div className="product">
            <Link to={Constants.SOOJI_PAGE_URL}><img src={soojiImage} alt="Sooji" className="img-fluid product-img" /></Link>
            <div className="text-content">
              <Link to={Constants.SOOJI_PAGE_URL} className="product-name">Sooji</Link>
              <div className="availability-content">
                <h6 className="heading">Available in:</h6>
                <div className="tags">
                  <div className="single-tag">500g</div>
                  <div className="single-tag">30Kg</div>
                  <div className="single-tag">50Kg</div>
                </div>
                <Link to={Constants.SOOJI_PAGE_URL} className="dark-btn">Read More</Link>
              </div>
            </div>
          </div>

          <div className="product">
            <Link to={Constants.MAIDA_PAGE_URL}><img src={maidaImage} alt="Maida" className="img-fluid product-img" /></Link>
            <div className="text-content">
              <Link to={Constants.MAIDA_PAGE_URL} className="product-name">Maida</Link>
              <div className="availability-content">
                <h6 className="heading">Available in:</h6>
                <div className="tags">
                <div className="single-tag">500g</div>
                  <div className="single-tag">30Kg</div>
                  <div className="single-tag">50Kg</div>
                </div>
                <Link to={Constants.MAIDA_PAGE_URL} className="dark-btn">Read More</Link>
              </div>
            </div>
          </div>

          <div className="product">
            <Link to={Constants.BESAN_PAGE_URL}><img src={besanImage} alt="Besan" className="img-fluid product-img" /></Link>
            <div className="text-content">
              <Link to={Constants.BESAN_PAGE_URL} className="product-name">Besan</Link>
              <div className="availability-content">
                <h6 className="heading">Available in:</h6>
                <div className="tags">
                  <div className="single-tag">500g</div>
                </div>
                <Link to={Constants.BESAN_PAGE_URL} className="dark-btn">Read More</Link>
              </div>
            </div>
          </div>

          <div className="product">
            <Link to={Constants.ATTA_PAGE_URL}><img src={attaImage} alt="Atta" className="img-fluid product-img" /></Link>
            <div className="text-content">
              <Link to={Constants.ATTA_PAGE_URL} className="product-name">Atta</Link>
              <div className="availability-content">
                <h6 className="heading">Available in:</h6>
                <div className="tags">
                  <div className="single-tag">5Kg</div>
                  <div className="single-tag">30Kg</div>
                  <div className="single-tag">50Kg</div>
                </div>
                <Link to={Constants.ATTA_PAGE_URL} className="dark-btn">Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-process-sec">
        <div className="container">
          <div className="common-heading-div">
            <h4 className="common-heading">Our Process</h4>
          </div>
          <div className="row our-process-row">
              <div className="col-lg-3 col-6 process-card-col">
                <div className="process-card">
                  <div className="icon-div">
                    <img src={roundedCircle} alt="Circle" className="img-fluid circle-icon" />
                    <img src={processIcon1} alt="Raw Products" className="img-fluid process-icon" />
                  </div>
                  <div className="text-content">
                    <h4 className="step-text">Step 1</h4>
                    <h5 className="process-name">Raw Products</h5>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-6 process-card-col">
                <div className="process-card">
                  <div className="icon-div">
                    <img src={roundedCircle} alt="Circle" className="img-fluid circle-icon" />
                    <img src={processIcon2} alt="Automatic Processing" className="img-fluid process-icon" />
                  </div>
                  <div className="text-content">
                    <h4 className="step-text">Step 2</h4>
                    <h5 className="process-name">Automatic Processing</h5>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-6 process-card-col">
                <div className="process-card">
                  <div className="icon-div">
                    <img src={roundedCircle} alt="Circle" className="img-fluid circle-icon" />
                    <img src={processIcon3} alt="Quality Check" className="img-fluid process-icon" />
                  </div>
                  <div className="text-content">
                    <h4 className="step-text">Step 3</h4>
                    <h5 className="process-name">Quality Check</h5>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-6 process-card-col">
                <div className="process-card">
                  <div className="icon-div">
                    <img src={roundedCircle} alt="Circle" className="img-fluid circle-icon" />
                    <img src={processIcon4} alt="Packaging" className="img-fluid process-icon" />
                  </div>
                  <div className="text-content">
                    <h4 className="step-text">Step 4</h4>
                    <h5 className="process-name">Packaging</h5>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>

      <section className="why-choose-section">
        <div className="container">
          <div className="common-heading-div">
            <h4 className="common-heading">Why Choose Us</h4>
          </div>
          
          <div className="card-row row">
            <div className="col-lg-4 col-md-6 card-col">
              <div className="inner-card">
                <div className="icon-div">
                  <img src={whyChooseIcon1} alt="Quality Assurance" className="img-fluid icon" />
                </div>
                <div className="text-contnt">
                  <h4 className="title">Quality Assurance</h4>
                  <p className="p-text">Our unwavering commitment to food safety and quality forms the bedrock of our business philosophy.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-col">
              <div className="inner-card">
                <div className="icon-div">
                  <img src={whyChooseIcon2} alt="Customer Focused" className="img-fluid icon" />
                </div>
                <div className="text-contnt">
                  <h4 className="title">Customer Focused</h4>
                  <p className="p-text">We believe in understanding and meeting customer needs in a professional and responsive manner.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-col">
              <div className="inner-card">
                <div className="icon-div">
                  <img src={whyChooseIcon3} alt="Passion For Excellence" className="img-fluid icon" />
                </div>
                <div className="text-contnt">
                  <h4 className="title">Passion For Excellence</h4>
                  <p className="p-text">We tenaciously chase excellence through continuous improvement in all our processes & products.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="product-showcase">
            <div className="img-div"><img src={soojiImage} alt="Sooji" className="img-fluid product-img wow slideInRight" /></div>
            <div className="img-div"><img src={maidaImage} alt="Maida" className="img-fluid product-img wow slideInRight" data-wow-delay="0.4s" /></div>
            <div className="img-div"><img src={besanImage} alt="Besan" className="img-fluid product-img wow slideInRight" data-wow-delay="0.8s"/></div>
            <div className="img-div"><img src={attaImage} alt="Atta" className="img-fluid product-img wow slideInRight" data-wow-delay="1.2s"/></div>
          </div>
        </div>
      </section>

      <section className="quick-contact-section">
        <div className="img-div">
            <img src={maidaImage} alt="Maida" className="img-fluid product-img wow slideInRightRotate" />
            <img src={besanImage} alt="Besan" className="img-fluid product-img wow slideInRightRotate" data-wow-delay="0.4s"/>
            <img src={attaImage} alt="Atta" className="img-fluid product-img wow slideInRightRotate" data-wow-delay="0.8s"/>
            <img src={soojiImage} alt="Sooji" className="img-fluid product-img wow slideInRightRotate" data-wow-delay="1.2s" />
        </div>
        <div className="container">
          <div className="text-content">
           <h4 className="common-heading"><span className="primary-color">Healthy</span> Choices<span className="primary-color">, Healthy</span> Living</h4>
           <div className="list-div">
              <div className="list"><img src={checkCircle} alt="Circle" className="img-fluid icon" />Rich in Protein</div>
              <div className="list"><img src={checkCircle} alt="Circle" className="img-fluid icon" />Wholesome Ingredients</div>
              <div className="list"><img src={checkCircle} alt="Circle" className="img-fluid icon" />Supporting Wellness</div>
           </div>
           <a href="tel:+918734017801" className="primary-btn">Call Us Now</a>
          </div>
        </div>
      </section>
    </Layout>
  );
}