import React, {useEffect} from "react";
import Layout from "./layout.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Constants from '../config/constants';
import WOW from "wowjs";
import "wowjs/dist/wow";
import "wowjs/css/libs/animate.css";
import productStandImg from '../images/img7.png';
import brProductImage from '../images/img1.png';
import blProductImage from '../images/img5.png';
import trProductImage from '../images/img4.png';
import wavingIcon2 from '../images/right-leaf.png';
import soojiImage from '../images/soji.png';
import maidaImage from '../images/maida.png';
import besanImage from '../images/besan.png';
import attaImage from '../images/atta.png';
import whyChooseIcon1 from '../images/icon5.svg';
import whyChooseIcon2 from '../images/icon6.svg';
import whyChooseIcon3  from '../images/icon7.svg';

export default function About() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const wow = new WOW.WOW({
    live: false,
    boxClass: 'wow',
    offset: 0,
    mobile: true,
  });
  wow.init();
  
  return (
    <Layout pageName={t("about")}>

      <section className="breadcrumbs-section">
        <div className="breadcrumbs-mdiv container">
          <h1 className="breadcrumbs-title">About</h1>
          <div className="breadcrumbs-link-div">
            <Link to={Constants.LANDING_PAGE_URL} className="breadcrumbs-link">Home</Link>/
            <p className="breadcrumbs-link">About</p>
          </div>
        </div>
      </section>

      <section className="about-section about-page">
        <img src={wavingIcon2} alt="Icon" className="waving-icon" />
        <div className="container">
          <div className="row about-section-row">
            <div className="col-xxl-5 col-lg-6 left-col img-col align-items-start">
              <div className="img-div sticy-col">
                <img src={brProductImage} alt="Atta" className="img-fluid product-img br-img wow fadeInRight" data-wow-delay="1.3s"/>
                <img src={blProductImage} alt="Besan" className="img-fluid product-img bl-img wow fadeInLeft" data-wow-delay="0.9s" />
                <img src={soojiImage} alt="Soji" className="img-fluid product-img tl-img wow fadeInLeft" />
                <img src={trProductImage} alt="Maida" className="img-fluid product-img tr-img wow fadeInRight" data-wow-delay="0.5s"/>
                <img src={productStandImg} alt={t('sonamor')} className="img-fluid product-stand" />
              </div>
            </div>
            <div className="col-xxl-7 col-lg-6 right-col text-content-col">
              <div className="text-content">
                <h2 className="common-heading">About sonamor</h2>
                <div className="p-text-div">
                  <p className="p-text">Welcome to Sonamor, the flagship brand of More Industries, dedicated to providing you with premium quality flour products. Sonamor has swiftly emerged as a trusted name in households and kitchens nationwide.</p>
                  <p className="p-text">At Sonamor, we take immense pride in crafting a diverse range of kitchen essentials, including sooji, maida, besan, and attas, all under the banner of excellence. Our commitment to quality is unwavering, ensuring that every product bearing the Sonamor name meets the highest standards of freshness, purity, and nutritional value. Driven by a passion for innovation and customer satisfaction, Sonamor is at the forefront of revolutionizing the flour industry. With a dedicated team of experts and cutting-edge technology, we continuously strive to enhance the quality and variety of our offerings, ensuring that every Sonamor product exceeds your expectations.</p>
                  <p className="p-text">What truly sets Sonamor apart is our relentless focus on customer delight. We understand the integral role our products play in your culinary creations, whether you're a seasoned chef or a home cook. That's why we go above and beyond to deliver flour products that consistently perform to perfection, elevating every dish you prepare.</p>
                  <p className="p-text">Beyond our commitment to quality, Sonamor is deeply committed to sustainability and environmental responsibility. We take proactive measures to minimize our ecological footprint, from sustainable sourcing practices to eco-friendly packaging solutions, ensuring that every Sonamor product not only nourishes your body but also nurtures our planet.</p>
                  <p className="p-text">As we continue to grow and expand our product line, our dedication to excellence, innovation, and customer satisfaction remains steadfast. We invite you to experience the Sonamor difference and join us on our journey to redefine excellence in the flour industry. Thank you for choosing Sonamor – where quality meets innovation, every time.</p>
                </div>
                <div className="tag"><span className="text">Healthy <span className="primary-color">Choices,</span> Healthy <span className="primary-color">Living</span></span></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose-section">
        <div className="container">
          <div className="common-heading-div">
            <h4 className="common-heading">Why Choose Us</h4>
          </div>
          
          <div className="card-row row">
            <div className="col-lg-4 col-md-6 card-col">
              <div className="inner-card">
                <div className="icon-div">
                  <img src={whyChooseIcon1} alt="Quality Assurance" className="img-fluid icon" />
                </div>
                <div className="text-contnt">
                  <h4 className="title">Quality Assurance</h4>
                  <p className="p-text">Our unwavering commitment to food safety and quality forms the bedrock of our business philosophy.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-col">
              <div className="inner-card">
                <div className="icon-div">
                  <img src={whyChooseIcon2} alt="Customer Focused" className="img-fluid icon" />
                </div>
                <div className="text-contnt">
                  <h4 className="title">Customer Focused</h4>
                  <p className="p-text">We believe in understanding and meeting customer needs in a professional and responsive manner.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 card-col">
              <div className="inner-card">
                <div className="icon-div">
                  <img src={whyChooseIcon3} alt="Passion For Excellence" className="img-fluid icon" />
                </div>
                <div className="text-contnt">
                  <h4 className="title">Passion For Excellence</h4>
                  <p className="p-text">We tenaciously chase excellence through continuous improvement in all our processes & products.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="product-showcase">
            <div className="img-div"><img src={soojiImage} alt="Sooji" className="img-fluid product-img wow slideInRight" /></div>
            <div className="img-div"><img src={maidaImage} alt="Maida" className="img-fluid product-img wow slideInRight" data-wow-delay="0.4s" /></div>
            <div className="img-div"><img src={besanImage} alt="Besan" className="img-fluid product-img wow slideInRight" data-wow-delay="0.8s"/></div>
            <div className="img-div"><img src={attaImage} alt="Atta" className="img-fluid product-img wow slideInRight" data-wow-delay="1.2s"/></div>
          </div>
        </div>
      </section>

    </Layout>
  );
}