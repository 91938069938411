import React, {useRef, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Layout from "./layout.js";
import "wowjs/dist/wow";
import "wowjs/css/libs/animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import * as Constants from '../config/constants';
import Atta5 from '../images/atta.png';
import Atta30_1 from '../images/atta-30kg-1.png';
import Atta30_2 from '../images/atta-30kg-2.png';
import Atta50 from '../images/atta-50kg.png';
import tandooriAtta50 from '../images/tandoori-atta-50.png';
import tandooriAtta30 from '../images/tandoori-atta-30.png';
import TandooriRoti from '../images/tandoori-roti.jpeg';
import ratta30 from '../images/r-atta-30.png';
import ratta50 from '../images/r-atta-50.png';
import FulkaRoti from '../images/fulka-roti.jpg';
import panipuri from '../images/pani-puri.jpg';
import panipuri50 from '../images/panipuri-atta-50kg.png';
import panipuri30 from '../images/panipuri-atta-30kg.png';
import Fancybox from "../js/fancybox.js";


export default function Atta() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const referenceproductSliderRef = useRef(null);
  const referenceproductSliderRef2 = useRef(null);
  const referenceproductSliderRef3 = useRef(null);
  const next = () => {referenceproductSliderRef.current.slickNext();};
  const previous = () => {referenceproductSliderRef.current.slickPrev();};
  const next2 = () => {referenceproductSliderRef2.current.slickNext();};
  const previous2 = () => {referenceproductSliderRef2.current.slickPrev();};
  const next3 = () => {referenceproductSliderRef3.current.slickNext();};
  const previous3 = () => {referenceproductSliderRef3.current.slickPrev();};

  // var referenceproductSliderSetting = { ref: referenceproductSliderRef, dots: false, infinite: false, speed: 500, autoplay: true, slidesToShow: 3, slidesToScroll: 1, responsive: [{ breakpoint: 991, settings: { slidesToShow: 1, infinite: false, } }, { breakpoint: 576, settings: { slidesToShow: 1, infinite: false, } }], }
  // var referenceproductSlider2 = { ref: referenceproductSliderRef2, dots: false, infinite: false, speed: 500, autoplay: true, slidesToShow: 3, slidesToScroll: 1, responsive: [{ breakpoint: 991, settings: { slidesToShow: 1, infinite: false, } }, { breakpoint: 576, settings: { slidesToShow: 1, infinite: false, } }], }
  // var referenceproductSlider3 = { ref: referenceproductSliderRef3, dots: false, infinite: false, speed: 500, autoplay: true, slidesToShow: 3, slidesToScroll: 1, responsive: [{ breakpoint: 991, settings: { slidesToShow: 1, infinite: false, } }, { breakpoint: 576, settings: { slidesToShow: 1, infinite: false, } }], }

  // const referenceproductSlider = {...referenceproductSliderSetting}
  
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [nav3, setNav3] = useState(null);
  const [nav4, setNav4] = useState(null);
  const [nav5, setNav5] = useState(null);
  const [nav6, setNav6] = useState(null);
  const [nav7, setNav7] = useState(null);
  const [nav8, setNav8] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  let sliderRef3 = useRef(null);
  let sliderRef4 = useRef(null);
  let sliderRef5 = useRef(null);
  let sliderRef6 = useRef(null);
  let sliderRef7 = useRef(null);
  let sliderRef8 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
    setNav3(sliderRef3);
    setNav4(sliderRef4);
    setNav5(sliderRef5);
    setNav6(sliderRef6);
    setNav7(sliderRef7);
    setNav8(sliderRef8);
  }, []);

  function SampleNextArrow(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
    );
  }
  
  function SamplePrevArrow(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
    );
  }

  function SampleNextArrow2(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
    );
  }
  
  function SamplePrevArrow2(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
    );
  }

  function SampleNextArrow3(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
    );
  }
  
  function SamplePrevArrow3(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
    );
  }

  var productSliderSetting = {    dots: false, focusOnSelect: false, pauseOnHover: false, fade: true, cssEase: 'linear', arrows: true, autoplay: true, autoplaySpeed: 3000, infinite: false, speed: 1, slidesToShow: 1, nextArrow:<SampleNextArrow />, prevArrow:<SamplePrevArrow />,}
 
  const productSlider  ={...productSliderSetting} 
  const premiumMaidaSlider  ={dots: false, focusOnSelect: false, pauseOnHover: false, fade: true, cssEase: 'linear', arrows: true, autoplay: true, autoplaySpeed: 3000, infinite: false, speed: 1, slidesToShow: 1, nextArrow:<SampleNextArrow2 />, prevArrow:<SamplePrevArrow2 />} 
  const premiumSarasSlider  ={dots: false, focusOnSelect: false, pauseOnHover: false, fade: true, cssEase: 'linear', arrows: true, autoplay: true, autoplaySpeed: 3000, infinite: false, speed: 1, slidesToShow: 1, nextArrow:<SampleNextArrow3 />, prevArrow:<SamplePrevArrow3 />} 
  const panipuriSlider  ={dots: false, focusOnSelect: false, pauseOnHover: false, fade: true, cssEase: 'linear', arrows: true, autoplay: true, autoplaySpeed: 3000, infinite: false, speed: 1, slidesToShow: 1, nextArrow:<SampleNextArrow3 />, prevArrow:<SamplePrevArrow3 />} 
  

  return (
    <Layout pageName={'Atta'}>

      <section className="breadcrumbs-section">
        <div className="breadcrumbs-mdiv container">
          <h1 className="breadcrumbs-title">Atta</h1>
          <div className="breadcrumbs-link-div">
            <Link to={Constants.LANDING_PAGE_URL} className="breadcrumbs-link">Home</Link>/
            <Link to={Constants.PRODUCTS_PAGE_URL} className="breadcrumbs-link">Products</Link>/
            <p className="breadcrumbs-link">Atta</p>
          </div>
        </div>
      </section>

      <section className="product-section common-slider-arrow section-padding overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="nav nav-tabs product-tab" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="sonamor-chakki-atta-tab" data-bs-toggle="tab" data-bs-target="#sonamor-chakki-atta" type="button" role="tab" aria-controls="sonamor-chakki-atta" aria-selected="true">Sonamor Chakki Atta</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="sonamor-tandoori-atta-tab" data-bs-toggle="tab" data-bs-target="#sonamor-tandoori-atta" type="button" role="tab" aria-controls="sonamor-tandoori-atta" aria-selected="false">Sonamor Tandoori Atta</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="sonamor-r-atta-tab" data-bs-toggle="tab" data-bs-target="#sonamor-r-atta" type="button" role="tab" aria-controls="sonamor-r-atta" aria-selected="false">Sonamor ( R ) Atta</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="sonamor-panipuri-atta-tab" data-bs-toggle="tab" data-bs-target="#sonamor-panipuri-atta" type="button" role="tab" aria-controls="sonamor-panipuri-atta" aria-selected="false">Sonamor Panipuri Atta</button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {/* Sonamor Chakki Atta  */}
                <div className="tab-pane fade show active" id="sonamor-chakki-atta" role="tabpanel" aria-labelledby="sonamor-chakki-atta-tab">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="product-sticky-div">
                        <Fancybox options={{Carousel: {infinite: false,},}}>
                          <Slider {...productSlider} asNavFor={nav2} ref={slider => (sliderRef1 = slider)} className="product-slider">
                              <a href={Atta5} data-fancybox="photos" className="slider-content">
                                <img src={Atta5} alt="Product" className="img-fluid product-slider-img" />
                              </a>

                              <a href={Atta30_1} data-fancybox="photos" className="slider-content">
                                <img src={Atta30_1} alt="Product" className="img-fluid product-slider-img" />
                              </a>

                              <a href={Atta30_2} data-fancybox="photos" className="slider-content">
                                <img src={Atta30_2} alt="Product" className="img-fluid product-slider-img" />
                              </a>

                              <a href={Atta50} data-fancybox="photos" className="slider-content">
                                <img src={Atta50} alt="Product" className="img-fluid product-slider-img" />
                              </a>
                            </Slider>
                        </Fancybox>
                        <Slider asNavFor={nav1} ref={slider => (sliderRef2 = slider)} arrows={false} slidesToShow={4} swipeToSlide={true} focusOnSelect={true} infinite={false} speed={50} className="product-weight-slider">
                          <div className="product-weight-div mb-2 mb-sm-0">
                            <p>5Kg</p>
                          </div>
                          <div className="product-weight-div mb-2 mb-sm-0">
                            <p>30Kg - 1</p>
                          </div>
                          <div className="product-weight-div mb-2 mb-sm-0">
                            <p>30Kg - 2</p>
                          </div>
                          <div className="product-weight-div mb-2 mb-sm-0">
                            <p>50Kg</p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <h3 className="product-name">Sonamor Chakki Atta</h3>
                      <div className="p-text-div">
                        <p className="p-text">Embark on a journey of wholesome goodness with Sonamor Atta, the cornerstone of healthy living in every kitchen. Crafted with care and precision, Sonamor Atta is synonymous with purity, nutrition, and superior taste, making it the preferred choice for health-conscious individuals and culinary enthusiasts alike. Sonamor Atta is made from the finest quality wheat grains, sourced from trusted farmers who share our commitment to quality and sustainability. Our rigorous selection process ensures that only the best grains are chosen, guaranteeing a product that's free from impurities and rich in essential nutrients. Whether you're baking soft rotis, fluffy puris, or hearty parathas, Sonamor Atta ensures that every meal you prepare is not just delicious but also nutritious.</p>
                        <p className="p-text">At Sonamor, we understand that the foundation of a healthy lifestyle begins with the food we consume. That's why we take great care to ensure that every batch of Sonamor Atta undergoes strict quality control measures to maintain its freshness, purity, and nutritional value. Our state-of-the-art production facilities and advanced technology ensure that you receive a product that's unmatched in quality and consistency.</p>
                        <p className="p-text">Whether you're a seasoned cook or a novice in the kitchen, our Atta is incredibly easy to work with, allowing you to experiment with a wide range of recipes and flavors. From traditional Indian dishes to international cuisines, Sonamor Atta is your trusted companion in creating culinary masterpieces that nourish the body and delight the senses.</p>
                      </div>
                      <div className="reference-product-mdiv">
                        <div className="reference-product-div">
                          <p className="reference-product-title">Use:</p>
                          <div className="reference-slider-btn d-none">
                            <button type="button" onClick={previous} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
                            <button type="button" onClick={next} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
                          </div>
                        </div>
                        <div className="row reference-slider">
                          <div className="col-lg-4 col-sm-6">
                            <div className="reference-slider-item">
                              <div className="reference-slider-img">
                                <img src={FulkaRoti} alt="Fulka Roti" className="img-fluid" />
                              </div>
                              <div className="reference-slider-content">
                                <p className="reference-slider-title">Fulka Roti</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Sonamor Tandoori Atta  */}
                <div className="tab-pane fade" id="sonamor-tandoori-atta" role="tabpanel" aria-labelledby="sonamor-tandoori-atta-tab">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="product-sticky-div">
                        <Fancybox options={{Carousel: {infinite: false,},}}>
                          <Slider {...premiumMaidaSlider} asNavFor={nav4} ref={slider => (sliderRef3 = slider)} className="product-slider">
                              <a href={tandooriAtta30} data-fancybox="photos" className="slider-content">
                                <img src={tandooriAtta30} alt="Product" className="img-fluid product-slider-img" />
                              </a>

                              <a href={tandooriAtta50} data-fancybox="photos" className="slider-content">
                                  <img src={tandooriAtta50} alt="Product" className="img-fluid product-slider-img" />
                              </a>
                            </Slider>
                        </Fancybox>
                        <Slider asNavFor={nav3} speed={50} ref={slider => (sliderRef4 = slider)} arrows={false} slidesToShow={2} swipeToSlide={true} focusOnSelect={true} infinite={false}  className="product-weight-slider">
                          <div className="product-weight-div">
                            <p>30Kg</p>
                          </div>
                          <div className="product-weight-div">
                            <p>50Kg</p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <h3 className="product-name">Sonamor Tandoori Atta</h3>
                      <div className="p-text-div">
                        <p className="p-text">Embark on a journey of wholesome goodness with Sonamor Atta, the cornerstone of healthy living in every kitchen. Crafted with care and precision, Sonamor Atta is synonymous with purity, nutrition, and superior taste, making it the preferred choice for health-conscious individuals and culinary enthusiasts alike. Sonamor Atta is made from the finest quality wheat grains, sourced from trusted farmers who share our commitment to quality and sustainability. Our rigorous selection process ensures that only the best grains are chosen, guaranteeing a product that's free from impurities and rich in essential nutrients. Whether you're baking soft rotis, fluffy puris, or hearty parathas, Sonamor Atta ensures that every meal you prepare is not just delicious but also nutritious.</p>
                        <p className="p-text">At Sonamor, we understand that the foundation of a healthy lifestyle begins with the food we consume. That's why we take great care to ensure that every batch of Sonamor Atta undergoes strict quality control measures to maintain its freshness, purity, and nutritional value. Our state-of-the-art production facilities and advanced technology ensure that you receive a product that's unmatched in quality and consistency.</p>
                        <p className="p-text">Whether you're a seasoned cook or a novice in the kitchen, our Atta is incredibly easy to work with, allowing you to experiment with a wide range of recipes and flavors. From traditional Indian dishes to international cuisines, Sonamor Atta is your trusted companion in creating culinary masterpieces that nourish the body and delight the senses.</p>
                      </div>
                      <div className="reference-product-mdiv">
                        <div className="reference-product-div">
                          <p className="reference-product-title">Use:</p>
                          <div className="reference-slider-btn d-none">
                            <button type="button" onClick={previous2} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
                            <button type="button" onClick={next2} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
                          </div>
                        </div>
                        <div className="row reference-slider">
                          <div className="col-lg-4 col-sm-6">
                            <div className="reference-slider-item">
                              <div className="reference-slider-img">
                                <img src={TandooriRoti} alt="Tandoori Roti" className="img-fluid" />
                              </div>
                              <div className="reference-slider-content">
                                <p className="reference-slider-title">Tandoori Roti</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Sonamor ( R ) Atta  */}
                <div className="tab-pane fade" id="sonamor-r-atta" role="tabpanel" aria-labelledby="sonamor-r-atta-tab">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="product-sticky-div">
                        <Fancybox options={{Carousel: {infinite: false,},}}>
                          <Slider {...premiumSarasSlider} asNavFor={nav5} ref={slider => (sliderRef6 = slider)} className="product-slider">
                              <a href={ratta30} data-fancybox="photos" className="slider-content">
                                <img src={ratta30} alt="Product" className="img-fluid product-slider-img" />
                              </a>

                              <a href={ratta50} data-fancybox="photos" className="slider-content">
                                <img src={ratta50} alt="Product" className="img-fluid product-slider-img" />
                              </a>
                            </Slider>
                        </Fancybox>
                        <Slider asNavFor={nav6} speed={50} ref={slider => (sliderRef5 = slider)} arrows={false} slidesToShow={2} swipeToSlide={true} focusOnSelect={true} infinite={false}  className="product-weight-slider">
                          <div className="product-weight-div">
                            <p>30Kg</p>
                          </div>
                          <div className="product-weight-div">
                            <p>50Kg</p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <h3 className="product-name">Sonamor ( R ) Atta</h3>
                      <div className="p-text-div">
                        <p className="p-text">Embark on a journey of wholesome goodness with Sonamor Atta, the cornerstone of healthy living in every kitchen. Crafted with care and precision, Sonamor Atta is synonymous with purity, nutrition, and superior taste, making it the preferred choice for health-conscious individuals and culinary enthusiasts alike. Sonamor Atta is made from the finest quality wheat grains, sourced from trusted farmers who share our commitment to quality and sustainability. Our rigorous selection process ensures that only the best grains are chosen, guaranteeing a product that's free from impurities and rich in essential nutrients. Whether you're baking soft rotis, fluffy puris, or hearty parathas, Sonamor Atta ensures that every meal you prepare is not just delicious but also nutritious.</p>
                        <p className="p-text">At Sonamor, we understand that the foundation of a healthy lifestyle begins with the food we consume. That's why we take great care to ensure that every batch of Sonamor Atta undergoes strict quality control measures to maintain its freshness, purity, and nutritional value. Our state-of-the-art production facilities and advanced technology ensure that you receive a product that's unmatched in quality and consistency.</p>
                        <p className="p-text">Whether you're a seasoned cook or a novice in the kitchen, our Atta is incredibly easy to work with, allowing you to experiment with a wide range of recipes and flavors. From traditional Indian dishes to international cuisines, Sonamor Atta is your trusted companion in creating culinary masterpieces that nourish the body and delight the senses.</p>
                      </div>
                      <div className="reference-product-mdiv">
                        <div className="reference-product-div">
                          <p className="reference-product-title">Use:</p>
                          <div className="reference-slider-btn d-none">
                            <button type="button" onClick={previous3} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
                            <button type="button" onClick={next3} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
                          </div>
                        </div>
                        <div className="row reference-slider">
                          <div className="col-lg-4 col-sm-6">
                            <div className="reference-slider-item">
                              <div className="reference-slider-img">
                                <img src={panipuri} alt="Panipuri" className="img-fluid" />
                              </div>
                              <div className="reference-slider-content">
                                <p className="reference-slider-title">Panipuri</p>
                              </div>
                            </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Sonamor Panipuri Atta  */}
                <div className="tab-pane fade" id="sonamor-panipuri-atta" role="tabpanel" aria-labelledby="sonamor-panipuri-atta-tab">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="product-sticky-div">
                        <Fancybox options={{Carousel: {infinite: false,},}}>
                          <Slider {...panipuriSlider} asNavFor={nav8} ref={slider => (sliderRef7 = slider)} className="product-slider">
                              <a href={panipuri30} data-fancybox="photos" className="slider-content">
                                <img src={panipuri30} alt="Product" className="img-fluid product-slider-img" />
                              </a>

                              <a href={panipuri50} data-fancybox="photos" className="slider-content">
                                <img src={panipuri50} alt="Product" className="img-fluid product-slider-img" />
                              </a>
                            </Slider>
                        </Fancybox>
                        <Slider asNavFor={nav7} speed={50} ref={slider => (sliderRef8 = slider)} arrows={false} slidesToShow={2} swipeToSlide={true} focusOnSelect={true} infinite={false}  className="product-weight-slider">
                          <div className="product-weight-div">
                            <p>30Kg</p>
                          </div>
                          <div className="product-weight-div">
                            <p>50Kg</p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <h3 className="product-name">Sonamor Panipuri Atta - <span className="primary-color">Good Quality</span></h3>
                      <div className="p-text-div">
                        <p className="p-text">Embark on a journey of wholesome goodness with Sonamor Atta, the cornerstone of healthy living in every kitchen. Crafted with care and precision, Sonamor Atta is synonymous with purity, nutrition, and superior taste, making it the preferred choice for health-conscious individuals and culinary enthusiasts alike. Sonamor Atta is made from the finest quality wheat grains, sourced from trusted farmers who share our commitment to quality and sustainability. Our rigorous selection process ensures that only the best grains are chosen, guaranteeing a product that's free from impurities and rich in essential nutrients. Whether you're baking soft rotis, fluffy puris, or hearty parathas, Sonamor Atta ensures that every meal you prepare is not just delicious but also nutritious.</p>
                        <p className="p-text">At Sonamor, we understand that the foundation of a healthy lifestyle begins with the food we consume. That's why we take great care to ensure that every batch of Sonamor Atta undergoes strict quality control measures to maintain its freshness, purity, and nutritional value. Our state-of-the-art production facilities and advanced technology ensure that you receive a product that's unmatched in quality and consistency.</p>
                        <p className="p-text">Whether you're a seasoned cook or a novice in the kitchen, our Atta is incredibly easy to work with, allowing you to experiment with a wide range of recipes and flavors. From traditional Indian dishes to international cuisines, Sonamor Atta is your trusted companion in creating culinary masterpieces that nourish the body and delight the senses.</p>
                      </div>
                      <div className="reference-product-mdiv">
                        <div className="reference-product-div">
                          <p className="reference-product-title">Use:</p>
                          <div className="reference-slider-btn d-none">
                            <button type="button" onClick={previous3} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
                            <button type="button" onClick={next3} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
                          </div>
                        </div>
                        <div className="row reference-slider">
                          <div className="col-lg-4 col-sm-6">
                            <div className="reference-slider-item">
                              <div className="reference-slider-img">
                                <img src={panipuri} alt="Panipuri" className="img-fluid" />
                              </div>
                              <div className="reference-slider-content">
                                <p className="reference-slider-title">Panipuri</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>

  );
}