import React, {useRef, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Layout from "./layout.js";
import "wowjs/dist/wow";
import "wowjs/css/libs/animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import * as Constants from '../config/constants';
import ProductImage2 from '../images/super-maida-50kg.png';
import Maida30KG from '../images/maida-30kg.png';
import Bread from '../images/bread.png';
import Biscuit from '../images/biscuit.png';
import Toast from '../images/toast.png';
import Khari from '../images/khari.jpg';
import Cookies from '../images/cookies.jpg';
import Cake from '../images/cake.jpg';
import premiumMaida500 from '../images/maida.png';
import premiumMaida30 from '../images/premium-maida-30kg.png';
import premiumMaida50 from '../images/premium-maida-50kg.png';
import Samosa from '../images/samosa.jpg';
import Kachori from '../images/kachori.jpg';
import farsiPuri from '../images/farsi-puri.jpg';
import sarasMaida30 from '../images/saras-maida-30.png';
import sarasMaida50 from '../images/saras-maida-50kg.png';
import Fancybox from "../js/fancybox.js";


export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const referenceproductSliderRef = useRef(null);
  const referenceproductSliderRef2 = useRef(null);
  const referenceproductSliderRef3 = useRef(null);
  const next = () => {referenceproductSliderRef.current.slickNext();};
  const previous = () => {referenceproductSliderRef.current.slickPrev();};
  const next2 = () => {referenceproductSliderRef2.current.slickNext();};
  const previous2 = () => {referenceproductSliderRef2.current.slickPrev();};
  const next3 = () => {referenceproductSliderRef3.current.slickNext();};
  const previous3 = () => {referenceproductSliderRef3.current.slickPrev();};

  var referenceproductSliderSetting = { ref: referenceproductSliderRef, dots: false, infinite: true, speed: 500, autoplay: true, slidesToShow: 3, slidesToScroll: 1, responsive: [{ breakpoint: 991, settings: { slidesToShow: 2, infinite: true, } }, { breakpoint: 576, settings: { slidesToShow: 1, infinite: true, } }], }
  var referenceproductSlider2 = { ref: referenceproductSliderRef2, dots: false, infinite: false, speed: 500, autoplay: true, slidesToShow: 3, slidesToScroll: 1, responsive: [{ breakpoint: 991, settings: { slidesToShow: 2, infinite: true, } }, { breakpoint: 576, settings: { slidesToShow: 1, infinite: true, } }], }
  var referenceproductSlider3 = { ref: referenceproductSliderRef3, dots: false, infinite: false, speed: 500, autoplay: true, slidesToShow: 3, slidesToScroll: 1, responsive: [{ breakpoint: 991, settings: { slidesToShow: 2, infinite: true, } }, { breakpoint: 576, settings: { slidesToShow: 1, infinite: true, } }], }

  const referenceproductSlider = {...referenceproductSliderSetting}
  
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [nav3, setNav3] = useState(null);
  const [nav4, setNav4] = useState(null);
  const [nav5, setNav5] = useState(null);
  const [nav6, setNav6] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  let sliderRef3 = useRef(null);
  let sliderRef4 = useRef(null);
  let sliderRef5 = useRef(null);
  let sliderRef6 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
    setNav3(sliderRef3);
    setNav4(sliderRef4);
    setNav5(sliderRef5);
    setNav6(sliderRef6);
  }, []);

  function SampleNextArrow(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
    );
  }
  
  function SamplePrevArrow(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
    );
  }

  function SampleNextArrow2(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
    );
  }
  
  function SamplePrevArrow2(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
    );
  }

  function SampleNextArrow3(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
    );
  }
  
  function SamplePrevArrow3(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
    );
  }

  var productSliderSetting = {    dots: false, focusOnSelect: false, pauseOnHover: false, fade: true, cssEase: 'linear', arrows: true, autoplay: true, autoplaySpeed: 3000, infinite: false, speed: 1, slidesToShow: 1, nextArrow:<SampleNextArrow />, prevArrow:<SamplePrevArrow />,}
 
  const productSlider  ={...productSliderSetting} 
  const premiumMaidaSlider  ={dots: false, focusOnSelect: false, pauseOnHover: false, fade: true, cssEase: 'linear', arrows: true, autoplay: true, autoplaySpeed: 3000, infinite: false, speed: 1, slidesToShow: 1, nextArrow:<SampleNextArrow2 />, prevArrow:<SamplePrevArrow2 />} 
  const premiumSarasSlider  ={dots: false, focusOnSelect: false, pauseOnHover: false, fade: true, cssEase: 'linear', arrows: true, autoplay: true, autoplaySpeed: 3000, infinite: false, speed: 1, slidesToShow: 1, nextArrow:<SampleNextArrow3 />, prevArrow:<SamplePrevArrow3 />} 
  

  return (
    <Layout pageName={'Maida'}>

      <section className="breadcrumbs-section">
        <div className="breadcrumbs-mdiv container">
          <h1 className="breadcrumbs-title">Maida</h1>
          <div className="breadcrumbs-link-div">
            <Link to={Constants.LANDING_PAGE_URL} className="breadcrumbs-link">Home</Link>/
            <Link to={Constants.PRODUCTS_PAGE_URL} className="breadcrumbs-link">Products</Link>/
            <p className="breadcrumbs-link">Maida</p>
          </div>
        </div>
      </section>

      <section className="product-section common-slider-arrow section-padding overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="nav nav-tabs product-tab" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="sonamor-super-maida-tab" data-bs-toggle="tab" data-bs-target="#sonamor-super-maida" type="button" role="tab" aria-controls="sonamor-super-maida" aria-selected="true">Sonamor Super Maida</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="sonamor-premium-maida-tab" data-bs-toggle="tab" data-bs-target="#sonamor-premium-maida" type="button" role="tab" aria-controls="sonamor-premium-maida" aria-selected="false">Sonamor Premium Maida</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="sonamor-saras-maida-tab" data-bs-toggle="tab" data-bs-target="#sonamor-saras-maida" type="button" role="tab" aria-controls="sonamor-saras-maida" aria-selected="false">Sonamor Saras Maida</button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {/* Sonamor Super Maida  */}
                <div className="tab-pane fade show active" id="sonamor-super-maida" role="tabpanel" aria-labelledby="sonamor-super-maida-tab">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="product-sticky-div">
                        <Fancybox options={{Carousel: {infinite: false,},}}>
                          <Slider {...productSlider} asNavFor={nav2} ref={slider => (sliderRef1 = slider)} className="product-slider">
                              <a href={Maida30KG} data-fancybox="photos" className="slider-content">
                                <img src={Maida30KG} alt="Product" className="img-fluid product-slider-img" />
                              </a>

                              <a href={ProductImage2} data-fancybox="photos" className="slider-content">
                                <img src={ProductImage2} alt="Product" className="img-fluid product-slider-img" />
                              </a>
                            </Slider>
                        </Fancybox>
                        <Slider asNavFor={nav1} speed={50} ref={slider => (sliderRef2 = slider)} arrows={false} slidesToShow={2} swipeToSlide={true} focusOnSelect={true} infinite={false}  className="product-weight-slider">
                          <div className="product-weight-div">
                            <p>30Kg</p>
                          </div>
                          <div className="product-weight-div">
                            <p>50Kg</p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <h3 className="product-name">Sonamor Super Maida</h3>
                      <div className="p-text-div">
                        <p className="p-text">Indulge in the superior quality and unparalleled versatility of Sonamor Maida, meticulously crafted to meet the demands of modern culinary excellence. Sonamor Maida stands as a testament to our commitment to delivering exceptional flour products that elevate your cooking to new levels of perfection. Sonamor Maida boasts a silky-smooth texture and pure white appearance, making it the ideal choice for a wide range of culinary delights. Whether you're baking fluffy cakes, preparing crispy fried snacks, or creating delicate pastries, our Maida guarantees impeccable results every time.</p>
                        <p className="p-text">At Sonamor, we understand that the success of your recipes depends on the quality of the ingredients you use. That's why we take great care to ensure that every batch of Sonamor Maida meets the highest standards of purity and freshness. Our state-of-the-art production facilities and stringent quality control measures ensure that you receive a product that's unmatched in quality and consistency.</p>
                        <p className="p-text">Whether you're a professional chef or a home baker, Sonamor Maida is your trusted partner in the kitchen, helping you create culinary masterpieces that leave a lasting impression. Elevate your cooking experience with Sonamor Maida – where flavor meets versatility, every time.</p>
                      </div>
                      <div className="reference-product-mdiv">
                        <div className="reference-product-div">
                          <p className="reference-product-title">Use:</p>
                          <div className="reference-slider-btn">
                            <button type="button" onClick={previous} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
                            <button type="button" onClick={next} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
                          </div>
                        </div>
                        <Slider {...referenceproductSlider} className="w-100 reference-slider">
                          
                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={Bread} alt="Bread" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Bread</p>
                            </div>
                          </div>

                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={Biscuit} alt="Biscuit" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Biscuit</p>
                            </div>
                          </div>

                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={Toast} alt="Toast" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Toast</p>
                            </div>
                          </div>

                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={Khari} alt="Khari" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Khari</p>
                            </div>
                          </div>

                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={Cookies} alt="Cookies" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Cookies</p>
                            </div>
                          </div>

                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={Cake} alt="Cake" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Cake</p>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Sonamor Premium Maida  */}
                <div className="tab-pane fade" id="sonamor-premium-maida" role="tabpanel" aria-labelledby="sonamor-premium-maida-tab">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="product-sticky-div">
                        <Fancybox options={{Carousel: {infinite: false,},}}>
                          <Slider {...premiumMaidaSlider} asNavFor={nav4} ref={slider => (sliderRef3 = slider)} className="product-slider">
                              <a href={premiumMaida500} data-fancybox="photos" className="slider-content">
                                <img src={premiumMaida500} alt="Product" className="img-fluid product-slider-img" />
                              </a>

                              <a href={premiumMaida30} data-fancybox="photos" className="slider-content">
                                  <img src={premiumMaida30} alt="Product" className="img-fluid product-slider-img" />
                              </a>

                              <a href={premiumMaida50} data-fancybox="photos" className="slider-content">
                                  <img src={premiumMaida50} alt="Product" className="img-fluid product-slider-img" />
                              </a>
                            </Slider>
                        </Fancybox>
                        <Slider asNavFor={nav3} speed={50} ref={slider => (sliderRef4 = slider)} arrows={false} slidesToShow={3} swipeToSlide={true} focusOnSelect={true} infinite={false}  className="product-weight-slider">
                          <div className="product-weight-div">
                            <p>500g</p>
                          </div>
                          <div className="product-weight-div">
                            <p>30Kg</p>
                          </div>
                          <div className="product-weight-div">
                            <p>50Kg</p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <h3 className="product-name">Sonamor Premium Maida - <span className="primary-color">Good Quality</span></h3>
                      <div className="p-text-div">
                        <p className="p-text">Indulge in the superior quality and unparalleled versatility of Sonamor Maida, meticulously crafted to meet the demands of modern culinary excellence. Sonamor Maida stands as a testament to our commitment to delivering exceptional flour products that elevate your cooking to new levels of perfection. Sonamor Maida boasts a silky-smooth texture and pure white appearance, making it the ideal choice for a wide range of culinary delights. Whether you're baking fluffy cakes, preparing crispy fried snacks, or creating delicate pastries, our Maida guarantees impeccable results every time.</p>
                        <p className="p-text">At Sonamor, we understand that the success of your recipes depends on the quality of the ingredients you use. That's why we take great care to ensure that every batch of Sonamor Maida meets the highest standards of purity and freshness. Our state-of-the-art production facilities and stringent quality control measures ensure that you receive a product that's unmatched in quality and consistency.</p>
                        <p className="p-text">Whether you're a professional chef or a home baker, Sonamor Maida is your trusted partner in the kitchen, helping you create culinary masterpieces that leave a lasting impression. Elevate your cooking experience with Sonamor Maida – where flavor meets versatility, every time.</p>
                      </div>
                      <div className="reference-product-mdiv">
                        <div className="reference-product-div">
                          <p className="reference-product-title">Use:</p>
                          <div className="reference-slider-btn d-lg-none">
                            <button type="button" onClick={previous2} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
                            <button type="button" onClick={next2} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
                          </div>
                        </div>
                        <Slider {...referenceproductSlider2} className="w-100 reference-slider">
                          
                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={Samosa} alt="Samosa" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Samosa</p>
                            </div>
                          </div>

                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={Kachori} alt="Kachori" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Kachori</p>
                            </div>
                          </div>

                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={farsiPuri} alt="Farshi Puri" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Farshi Puri</p>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Sonamor Saras Maida  */}
                <div className="tab-pane fade" id="sonamor-saras-maida" role="tabpanel" aria-labelledby="sonamor-saras-maida-tab">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="product-sticky-div">
                        <Fancybox options={{Carousel: {infinite: false,},}}>
                          <Slider {...premiumSarasSlider} asNavFor={nav5} ref={slider => (sliderRef6 = slider)} className="product-slider">
                              <a href={sarasMaida30} data-fancybox="photos" className="slider-content">
                                <img src={sarasMaida30} alt="Product" className="img-fluid product-slider-img" />
                              </a>

                              <a href={sarasMaida50} data-fancybox="photos" className="slider-content">
                                <img src={sarasMaida50} alt="Product" className="img-fluid product-slider-img" />
                              </a>
                            </Slider>
                        </Fancybox>
                        <Slider asNavFor={nav6} speed={50} ref={slider => (sliderRef5 = slider)} arrows={false} slidesToShow={2} swipeToSlide={true} focusOnSelect={true} infinite={false}  className="product-weight-slider">
                          <div className="product-weight-div">
                            <p>30Kg</p>
                          </div>
                          <div className="product-weight-div">
                            <p>50Kg</p>
                          </div>
                        </Slider>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <h3 className="product-name">Sonamor Saras Maida</h3>
                      <div className="p-text-div">
                        <p className="p-text">Indulge in the superior quality and unparalleled versatility of Sonamor Maida, meticulously crafted to meet the demands of modern culinary excellence. Sonamor Maida stands as a testament to our commitment to delivering exceptional flour products that elevate your cooking to new levels of perfection. Sonamor Maida boasts a silky-smooth texture and pure white appearance, making it the ideal choice for a wide range of culinary delights. Whether you're baking fluffy cakes, preparing crispy fried snacks, or creating delicate pastries, our Maida guarantees impeccable results every time.</p>
                        <p className="p-text">At Sonamor, we understand that the success of your recipes depends on the quality of the ingredients you use. That's why we take great care to ensure that every batch of Sonamor Maida meets the highest standards of purity and freshness. Our state-of-the-art production facilities and stringent quality control measures ensure that you receive a product that's unmatched in quality and consistency.</p>
                        <p className="p-text">Whether you're a professional chef or a home baker, Sonamor Maida is your trusted partner in the kitchen, helping you create culinary masterpieces that leave a lasting impression. Elevate your cooking experience with Sonamor Maida – where flavor meets versatility, every time.</p>
                      </div>
                      <div className="reference-product-mdiv">
                        <div className="reference-product-div">
                          <p className="reference-product-title">Use:</p>
                          <div className="reference-slider-btn d-lg-none">
                            <button type="button" onClick={previous3} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
                            <button type="button" onClick={next3} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
                          </div>
                        </div>
                        <Slider {...referenceproductSlider3} className="w-100 reference-slider">
                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={Samosa} alt="Samosa" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Samosa</p>
                            </div>
                          </div>

                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={Kachori} alt="Kachori" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Kachori</p>
                            </div>
                          </div>

                          <div className="reference-slider-item">
                            <div className="reference-slider-img">
                              <img src={farsiPuri} alt="Farshi Puri" className="img-fluid" />
                            </div>
                            <div className="reference-slider-content">
                              <p className="reference-slider-title">Farshi Puri</p>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>

  );
}