import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as Constants from "./constants";  

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    backend: {
        loadPath: `${Constants.LANGUAGE_FOLDER_URL}/locales/{{lng}}/{{ns}}.json`,
    },
    react: { 
      useSuspense: false
    }
  });
export default i18n;