import React, {useRef, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Layout from "./layout.js";
import "wowjs/dist/wow";
import "wowjs/css/libs/animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import * as Constants from '../config/constants';
import besan from '../images/besan.png';
import MethiBhajiya from '../images/methi-bhajiya.png';
import Gota from '../images/gota.png';
import namkeen from '../images/namkeen.jpg';
import Fancybox from "../js/fancybox.js";


export default function Besan() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const referenceproductSliderRef = useRef(null);
  const next = () => {referenceproductSliderRef.current.slickNext();};
  const previous = () => {referenceproductSliderRef.current.slickPrev();};

  var referenceproductSliderSetting = { ref: referenceproductSliderRef, dots: false, infinite: false, speed: 500, autoplay: true, slidesToShow: 3, slidesToScroll: 1, responsive: [{ breakpoint: 991, settings: { slidesToShow: 2, infinite: true, } }, { breakpoint: 576, settings: { slidesToShow: 1, infinite: true, } }], }

  const referenceproductSlider = {...referenceproductSliderSetting}
  
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  var productSliderSetting = {    dots: false, focusOnSelect: false, pauseOnHover: false, fade: true, cssEase: 'linear', arrows: true, autoplay: true, autoplaySpeed: 3000, infinite: false, speed: 1, slidesToShow: 1,}
 
  const productSlider  ={...productSliderSetting} 
  

  return (
    <Layout pageName={'Besan'}>

      <section className="breadcrumbs-section">
        <div className="breadcrumbs-mdiv container">
          <h1 className="breadcrumbs-title">Besan</h1>
          <div className="breadcrumbs-link-div">
            <Link to={Constants.LANDING_PAGE_URL} className="breadcrumbs-link">Home</Link>/
            <Link to={Constants.PRODUCTS_PAGE_URL} className="breadcrumbs-link">Products</Link>/
            <p className="breadcrumbs-link">Besan</p>
          </div>
        </div>
      </section>

      <section className="product-section common-slider-arrow section-padding overflow-hidden">
        <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="product-sticky-div">
                  <Fancybox options={{Carousel: {infinite: false,},}}>
                    <Slider {...productSlider} asNavFor={nav2} ref={slider => (sliderRef1 = slider)} className="product-slider">
                        <a href={besan} data-fancybox="photos" className="slider-content">
                          <img src={besan} alt="Product" className="img-fluid product-slider-img" />
                        </a>
                      </Slider>
                  </Fancybox>
                  <Slider asNavFor={nav1} speed={50} ref={slider => (sliderRef2 = slider)} arrows={false} slidesToShow={3} swipeToSlide={true} focusOnSelect={true} infinite={false}  className="product-weight-slider">
                    <div className="product-weight-div">
                      <p>500g</p>
                    </div>
                  </Slider>
                </div>
              </div>
              <div className="col-lg-8">
                <h3 className="product-name">Sonamor Besan</h3>
                <div className="p-text-div">
                  <p className="p-text">Savor the essence of tradition with Sonamor Besan, a hallmark of quality and authenticity in every kitchen. Crafted with passion and precision, Sonamor Besan brings the rich flavors and textures of Indian cuisine to life, promising a culinary experience like no other. Made from carefully selected chana dal (gram) sourced from trusted farmers, Sonamor Besan undergoes a meticulous milling process to ensure its fine texture and superior taste. Our commitment to quality and purity means that every batch of Sonamor Besan is free from additives and impurities, delivering an authentic taste that's true to its roots.</p>
                  <p className="p-text">At Sonamor, we understand the integral role that Besan plays in Indian cooking. Whether you're preparing mouthwatering gota, bhajiya, delectable sweets, or savory snacks, our Besan is the secret ingredient that adds depth and richness to your dishes. Its smooth texture and nutty flavor enhance the taste of every recipe, making every bite a delightful experience. Sonamor Besan isn't just a flour - it's a reflection of our heritage and a celebration of our culinary traditions. With its high protein content and essential nutrients, our Besan provides the nourishment your body needs while tantalizing your taste buds with its rich, authentic flavor.</p>
                  <p className="p-text">Whether you're cooking for your family or entertaining guests, Sonamor Besan is your trusted companion in creating memorable meals that honor tradition and bring people together. Experience the authentic taste of Indian cuisine with Sonamor Besan - where quality meets tradition, and every dish tells a story. Embrace the flavors of the past and create new culinary memories with Sonamor Besan - the essence of tradition, reimagined for today's kitchen.</p>
                </div>
                <div className="reference-product-mdiv">
                  <div className="reference-product-div">
                    <p className="reference-product-title">Use:</p>
                    <div className="reference-slider-btn d-lg-none">
                      <button type="button" onClick={previous} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
                      <button type="button" onClick={next} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
                    </div>
                  </div>
                  <Slider {...referenceproductSlider} className="w-100 reference-slider">
                    
                    <div className="reference-slider-item">
                      <div className="reference-slider-img">
                        <img src={MethiBhajiya} alt="Methi Bhajiya" className="img-fluid" />
                      </div>
                      <div className="reference-slider-content">
                        <p className="reference-slider-title">Methi Bhajiya</p>
                      </div>
                    </div>

                    <div className="reference-slider-item">
                      <div className="reference-slider-img">
                        <img src={Gota} alt="Gota" className="img-fluid" />
                      </div>
                      <div className="reference-slider-content">
                        <p className="reference-slider-title">Gota</p>
                      </div>
                    </div>

                    <div className="reference-slider-item">
                      <div className="reference-slider-img">
                        <img src={namkeen} alt="Namkeen" className="img-fluid" />
                      </div>
                      <div className="reference-slider-content">
                        <p className="reference-slider-title">Namkeen</p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
      </section>

    </Layout>

  );
}