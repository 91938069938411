import React, {useRef, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Layout from "./layout.js";
import "wowjs/dist/wow";
import "wowjs/css/libs/animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import * as Constants from '../config/constants';
import Sooji500 from '../images/soji.png';
import Sooji50 from '../images/sooji-50kg.png';
import Sooji30 from '../images/sooji-30.png';
import Upma from '../images/upma.jpg';
import Halva from '../images/halva.jpg';
import Utapaam from '../images/uttapam.png';
import soojiFula from '../images/sooji-fula.jpg';
import Fancybox from "../js/fancybox.js";


export default function Sooji() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const referenceproductSliderRef = useRef(null);
  const next = () => {referenceproductSliderRef.current.slickNext();};
  const previous = () => {referenceproductSliderRef.current.slickPrev();};

  var referenceproductSliderSetting = { ref: referenceproductSliderRef, dots: false, infinite: true, speed: 500, autoplay: true, slidesToShow: 3, slidesToScroll: 1, responsive: [{ breakpoint: 991, settings: { slidesToShow: 2, infinite: true, } }, { breakpoint: 576, settings: { slidesToShow: 1, infinite: true, } }], }

  const referenceproductSlider = {...referenceproductSliderSetting}
  
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  function SampleNextArrow(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
    );
  }
  
  function SamplePrevArrow(props) {
    const {onClick } = props;
    return (
      <button type="button" onClick={onClick} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
    );
  }
  var productSliderSetting = {    dots: false, focusOnSelect: false, pauseOnHover: false, fade: true, cssEase: 'linear', arrows: true, autoplay: true, autoplaySpeed: 3000, infinite: false, speed: 1, slidesToShow: 1, nextArrow:<SampleNextArrow />, prevArrow:<SamplePrevArrow />,}
 
  const productSlider  ={...productSliderSetting} 
  

  return (
    <Layout pageName={'Sooji'}>

      <section className="breadcrumbs-section">
        <div className="breadcrumbs-mdiv container">
          <h1 className="breadcrumbs-title">Sooji</h1>
          <div className="breadcrumbs-link-div">
            <Link to={Constants.LANDING_PAGE_URL} className="breadcrumbs-link">Home</Link>/
            <Link to={Constants.PRODUCTS_PAGE_URL} className="breadcrumbs-link">Products</Link>/
            <p className="breadcrumbs-link">Sooji</p>
          </div>
        </div>
      </section>

      <section className="product-section common-slider-arrow section-padding overflow-hidden">
        <div className="container">
            <div className="row">
                      <div className="col-lg-4">
                        <div className="product-sticky-div">
                          <Fancybox options={{Carousel: {infinite: false,},}}>
                            <Slider {...productSlider} asNavFor={nav2} ref={slider => (sliderRef1 = slider)} className="product-slider">
                                <a href={Sooji500} data-fancybox="photos" className="slider-content">
                                  <img src={Sooji500} alt="Product" className="img-fluid product-slider-img" />
                                </a>

                                <a href={Sooji30} data-fancybox="photos" className="slider-content">
                                  <img src={Sooji30} alt="Product" className="img-fluid product-slider-img" />
                                </a>

                                <a href={Sooji50} data-fancybox="photos" className="slider-content">
                                  <img src={Sooji50} alt="Product" className="img-fluid product-slider-img" />
                                </a>
                              </Slider>
                          </Fancybox>
                          <Slider asNavFor={nav1} speed={50} ref={slider => (sliderRef2 = slider)} arrows={false} slidesToShow={3} swipeToSlide={true} focusOnSelect={true} infinite={false}  className="product-weight-slider">
                            <div className="product-weight-div">
                              <p>500g</p>
                            </div>
                            <div className="product-weight-div">
                              <p>30Kg</p>
                            </div>
                            <div className="product-weight-div">
                              <p>50Kg</p>
                            </div>
                          </Slider>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <h3 className="product-name">Sonamor Sooji</h3>
                        <div className="p-text-div">
                          <p className="p-text">Experience the unparalleled quality and versatility of Sonamor Sooji, crafted with care and precision to elevate your culinary creations to new heights. Made from the finest quality wheat grains, our Sooji promises superior taste, texture, and nutrition, making it a must-have staple in every kitchen.</p>
                          <p className="p-text">At Sonamor, we understand the importance of Sooji in traditional and modern cooking alike. Whether you're preparing the perfect halwa, fluffy idlis, crispy dosas & uttapam, or delectable sweets, our Sooji ensures exceptional results every time. Its fine texture and purity guarantee a smooth consistency, allowing you to achieve the perfect balance of flavors in your dishes. We take pride in our meticulous production process, where every batch of Sonamor Sooji undergoes rigorous quality checks to ensure freshness and purity. From sourcing the finest wheat grains to precision grinding, we prioritize quality at every step to deliver a product that meets the highest standards of excellence.</p>
                          <p className="p-text">Whether you're a seasoned chef or a home cook, Sonamor Sooji is your trusted partner in the kitchen, helping you create memorable meals that delight the senses and nourish the body. Elevate your culinary experience with Sonamor Sooji - where quality meets taste, every time.</p>
                        </div>
                        <div className="reference-product-mdiv">
                          <div className="reference-product-div">
                            <p className="reference-product-title">Use:</p>
                            <div className="reference-slider-btn">
                              <button type="button" onClick={previous} className="slider-prev slider-btn"><i className="fi fi-rr-arrow-left icon"></i></button>
                              <button type="button" onClick={next} className="slider-next slider-btn"><i className="fi fi-rr-arrow-right icon"></i></button>
                            </div>
                          </div>
                          <Slider {...referenceproductSlider} className="w-100 reference-slider">
                            
                            <div className="reference-slider-item">
                              <div className="reference-slider-img">
                                <img src={Upma} alt="Upma" className="img-fluid" />
                              </div>
                              <div className="reference-slider-content">
                                <p className="reference-slider-title">Upma</p>
                              </div>
                            </div>

                            <div className="reference-slider-item">
                              <div className="reference-slider-img">
                                <img src={Halva} alt="Halva" className="img-fluid" />
                              </div>
                              <div className="reference-slider-content">
                                <p className="reference-slider-title">Halva</p>
                              </div>
                            </div>

                            <div className="reference-slider-item">
                              <div className="reference-slider-img">
                                <img src={Utapaam} alt="Uttapam" className="img-fluid" />
                              </div>
                              <div className="reference-slider-content">
                                <p className="reference-slider-title">Uttapam</p>
                              </div>
                            </div>

                            <div className="reference-slider-item">
                              <div className="reference-slider-img">
                                <img src={soojiFula} alt="soojiFula" className="img-fluid" />
                              </div>
                              <div className="reference-slider-content">
                                <p className="reference-slider-title">Sooji Fula</p>
                              </div>
                            </div>
                          </Slider>
                        </div>
                      </div>
            </div>
          </div>
      </section>

    </Layout>

  );
}