import React, {useEffect, useState} from "react";
import { NavLink, Link  } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '@fortawesome/fontawesome-free/css/all.css';
import '@flaticon/flaticon-uicons/css/all/all.css';
import * as Constants from "../../config/constants";
import $ from "jquery";
import {toggleNav} from '../../js/common';
import { useTranslation } from "react-i18next";
import mainLogo from '../../images/logo.png';
import phoneIcon from '../../images/phone-call.svg';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

export default function Header() {
  const { t } = useTranslation();
  
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownClick = (event) => {
    setIsOpen(!isOpen);
    event.stopPropagation();
  };
  useEffect(() => {
    const navMain = document.querySelector("#navMain");
    const minHeight = navMain.offsetHeight;
    document.querySelector(".main-navbar-wrapper").style.minHeight = minHeight + "px";
  }, []);

  return (
  <header>
    <div className="main-navbar-wrapper">
      <Link to={Constants.LANDING_PAGE_URL} className="logo-sec">
          <img src={mainLogo} alt={t('sonamor')} className="img-fluid main-logo" />
      </Link>
      <div className="navs">
        <div className="nav-top w-100">
            <div className="d-lg-flex">
                <p className="location"><i className="fi fi-rr-marker d-flex align-items-center"></i> Gandhinagar, Gujarat.</p>
            </div>
        </div>
          <nav className="navbar twt-navbar twt-navbar-common navbar-expand-xl flex-lg-column" id="navMain">
              <NavLink className="logo-sec" to={Constants.LANDING_PAGE_URL}>
                <img src={mainLogo} alt={t('sonamor')} className="img-fluid navbar-scroll-logo"/>
              </NavLink>
            <div className="nav-bottom bg-theme w-100">
              <div className="nav-bottom-content">
                <div className="slide navbar-slide" id="slideNav">
                  <ul className="navbar-nav p-0 w-100 align-items-center" id="elastic_parent" data-bs-targets=".nav-item">
                    <li className="nav-item">
                      <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to={Constants.LANDING_PAGE_URL}>{t("home")}</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to={Constants.ABOUT_US_PAGE_URL}>{t("about")}</NavLink>
                    </li>
                    <li className="nav-item dropdown">
                      <Link to={Constants.PRODUCTS_PAGE_URL} className="nav-link dropdown-toggle d-none d-lg-flex"><span className="">{t("products")}</span></Link>
                      <a href="sonamor" className="nav-link dropdown-toggle d-lg-none d-flex" onClick={handleDropdownClick} data-bs-toggle="dropdown"><span className="">{t("products")}</span></a>
                      <ul className="dropdown-menu">
                        <li className="d-block d-lg-none d-flex"><NavLink to={Constants.PRODUCTS_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')}>All Products</NavLink></li>
                        <li><NavLink to={Constants.SOOJI_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')}>Sooji</NavLink></li>
                        <li><NavLink to={Constants.MAIDA_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')}>Maida</NavLink></li>
                        <li><NavLink to={Constants.BESAN_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')}>Besan</NavLink></li>
                        <li><NavLink to={Constants.ATTA_PAGE_URL} className={({ isActive }) => (isActive ? 'dropdown-item active' : 'dropdown-item')}>Atta</NavLink></li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to={Constants.CONTACT_US_PAGE_URL}>{t("contact-us")}</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="call-book-sec">
                    <div className="call-us-cnt">
                        <img src={phoneIcon} alt="Call Us On" className="img-fluid call-icon" />
                        <div className="text-cnt">
                          <p className="txt">Call Us On</p>
                          <a href="tel:+918734017801" className="number">+91 87340 17801</a>
                        </div>
                    </div>
                    <Link to={Constants.CONTACT_US_PAGE_URL} className="primary-btn book-a-table">Enquiry</Link>
                </div>
                <button type="button" className="btn navbar-toggler border-0" id="slide-toggle" onClick={toggleNav}><span className="navbar-dash" /><span className="navbar-dash" /><span className="navbar-dash" /></button>
              </div>
            </div>
        </nav>
      </div>
    </div>
  </header>
  );
}