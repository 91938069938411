import React, { useEffect } from "react";
import Layout from "./layout.js";
import PageNotFoundImage from '../images/page-not-found.png'
import { Link } from "react-router-dom";
import * as Constants from "../config/constants";

export default function Clients() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout pageName="Page Not Found">
      <div className="common-pageheading" style={{background: "none",marginTop: "0"}}></div>
      <main className="page-height">
        <section className="page-not-found-section">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-8">
                <div className="page-not-found mb-4 mb-lg-0">
                  <img
                    className="mr-4 icon-gmail w-100"
                    src={PageNotFoundImage}
                    alt="Page Not Found"
                  />
                </div>
              </div>
              <div className="col-lg-12 text-center">
                <div className="button d-flex justify-content-center mb-4">
                  <Link
                    to={Constants.LANDING_PAGE_URL}
                    className="primary-btn"
                  >Back To Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
