import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Layout from "./layout.js";
import "wowjs/dist/wow";
import "wowjs/css/libs/animate.css";
import * as Constants from '../config/constants';
import soojiImage from '../images/soji.png';
import maidaImage from '../images/maida.png';
import besanImage from '../images/besan.png';
import attaImage from '../images/atta.png';


export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout pageName={'Products'}>

      <section className="breadcrumbs-section">
        <div className="breadcrumbs-mdiv container">
          <h1 className="breadcrumbs-title">Products</h1>
          <div className="breadcrumbs-link-div">
            <Link to={Constants.LANDING_PAGE_URL} className="breadcrumbs-link">Home</Link>/
            <p className="breadcrumbs-link">Products</p>
          </div>
        </div>
      </section>

      <section className="product-section">
        <div className="products-row">
          <div className="product">
            <Link to={Constants.SOOJI_PAGE_URL}><img src={soojiImage} alt="Sooji" className="img-fluid product-img" /></Link>
            <div className="text-content">
              <Link to={Constants.SOOJI_PAGE_URL} className="product-name">Sooji</Link>
              <div className="availability-content">
                <h6 className="heading">Available in:</h6>
                <div className="tags">
                  <div className="single-tag">500g</div>
                  <div className="single-tag">30Kg</div>
                  <div className="single-tag">50Kg</div>
                </div>
                <Link to={Constants.SOOJI_PAGE_URL} className="dark-btn">Read More</Link>
              </div>
            </div>
          </div>

          <div className="product">
            <Link to={Constants.MAIDA_PAGE_URL}><img src={maidaImage} alt="Maida" className="img-fluid product-img" /></Link>
            <div className="text-content">
              <Link to={Constants.MAIDA_PAGE_URL} className="product-name">Maida</Link>
              <div className="availability-content">
                <h6 className="heading">Available in:</h6>
                <div className="tags">
                <div className="single-tag">500g</div>
                  <div className="single-tag">30Kg</div>
                  <div className="single-tag">50Kg</div>
                </div>
                <Link to={Constants.MAIDA_PAGE_URL} className="dark-btn">Read More</Link>
              </div>
            </div>
          </div>

          <div className="product">
            <Link to={Constants.BESAN_PAGE_URL}><img src={besanImage} alt="Besan" className="img-fluid product-img" /></Link>
            <div className="text-content">
              <Link to={Constants.BESAN_PAGE_URL} className="product-name">Besan</Link>
              <div className="availability-content">
                <h6 className="heading">Available in:</h6>
                <div className="tags">
                  <div className="single-tag">500g</div>
                </div>
                <Link to={Constants.BESAN_PAGE_URL} className="dark-btn">Read More</Link>
              </div>
            </div>
          </div>

          <div className="product">
            <Link to={Constants.ATTA_PAGE_URL}><img src={attaImage} alt="Atta" className="img-fluid product-img" /></Link>
            <div className="text-content">
              <Link to={Constants.ATTA_PAGE_URL} className="product-name">Atta</Link>
              <div className="availability-content">
                <h6 className="heading">Available in:</h6>
                <div className="tags">
                  <div className="single-tag">5Kg</div>
                  <div className="single-tag">30Kg</div>
                  <div className="single-tag">50Kg</div>
                </div>
                <Link to={Constants.ATTA_PAGE_URL} className="dark-btn">Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>

  );
}