import React from "react";
import { Route, Routes } from "react-router-dom";
import * as Constants from "../config/constants";
import Home from "../pages/home";
import Contact from "../pages/contact-us.js";
import About from "../pages/about-us.js";
import Error404 from "../pages/404-error";
import Maida from "../pages/maida";
import Sooji from "../pages/sooji.js";
import Besan from "../pages/besan.js";
import Atta from "../pages/atta.js";
import Products from "../pages/products";

export const FrontendRoute = () => {
    return (
        <Routes>
            <Route key="frontend-1" path={Constants.LANDING_PAGE_URL} element={<Home />} />
            <Route key="frontend-2" path={Constants.HOME_PAGE_URL} element={<Home />} />
            <Route key="frontend-3" path={Constants.CONTACT_US_PAGE_URL} element={<Contact />} />
            <Route key="frontend-4" path={Constants.ABOUT_US_PAGE_URL} element={<About />} />
            <Route key="frontend-5" path="*" element={<Error404 />} />
            <Route key="frontend-6" path={Constants.MAIDA_PAGE_URL} element={<Maida />} />
            <Route key="frontend-7" path={Constants.SOOJI_PAGE_URL} element={<Sooji />} />
            <Route key="frontend-8" path={Constants.BESAN_PAGE_URL} element={<Besan />} />
            <Route key="frontend-9" path={Constants.ATTA_PAGE_URL} element={<Atta />} />
            <Route key="frontend-10" path={Constants.PRODUCTS_PAGE_URL} element={<Products />} />
        </Routes>
    )
}