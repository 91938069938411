import React, { useEffect } from "react";
import Header from './layout/header';
import Footer from './layout/footer';
import { seo } from '../seo';
import { useTranslation } from "react-i18next";
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;


export default function Layout({ children , pageName }) {
  const { t } = useTranslation();

  useEffect(() => {
    seo({
      title: pageName + ' | ' + t('sonamor') ,
    });
  });
  
  return (
    <>
      <Header />
      <main>{children}</main> 
      <div className="whatsapp-main-fixed-part shadow whatsapp-icon">
        <div className="circle">
          <a className=" link-unstyled cover-a-tag" target="_blank"
            href="https://api.whatsapp.com/send?phone=918734017801" rel="noreferrer">
            <i className="fab fa-whatsapp fa-2x text-white whatsaap-color"></i>
          </a>
        </div>
      </div>
      <Footer />
    </>
  )
}